nav{
    width: 100%;
    height: 80px;
    background-color: transparent;
    position: fixed;
    top: 0;
    z-index: 1000;
    /* border-bottom: 1px  solid #cccc; */
    display: flex;
    justify-content: space-between;
    padding: 0 clamp(1rem, 2vw, 3rem);
}
nav .logo{
    cursor: pointer;
    margin-top: -30px;
    z-index: 10000;
}
nav > .logo > img{
    height: 180px;
}

nav > .links > ul{
    display: flex;
    gap: 30px;
    list-style: none;
    margin-top: 2rem;
    padding: 6px 20px;
    border-radius: 5px;
    font-size: 20px;
    align-items: center;
}
nav > .links > ul a{
    color: var(--text-dark);
    text-decoration: none;
    position: relative;
    font-weight: 500;
}
nav > .links > ul a::before{
    content: '';
    width: 0%;
    height: 1px;
    background-color: var(--color1);
    position: absolute;
    bottom: 0px;
    left: 0;
    transition: width 200ms var(--ease);
}
nav > .links > ul a:hover::before{
    width: 100%;
}
.Dropdown{
    overflow: hidden;
}
.Dropdown .dropdown-content{
    padding: 1rem;
    display: flex;
    opacity: 0;
    flex-direction: column;
    position: absolute;
    gap: 10px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.116) 2px 3px 10px 0px;
    border-radius: 15px;
    margin-left: -1rem;
    transition: opacity 200ms ease-in-out;
    pointer-events: none;
}
nav > .links > ul .Dropdown:hover .dropdown-content{
    opacity: 1;
    pointer-events: all;
}
nav .icon{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 40px;
    height: 27px;
    position: fixed;
    top: 40px;
    right: 30px;
    
    position: relative
}
nav .icon > .line{
    width: 100%;
    height: 1px;
    background-color: var(--color1);
    z-index: 10000;
}
.links.mobile{

}
.slider{
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease-out;
    transform: translateX(-100%);
}
.slider.active{
    transform: translateX(0%);
}
.slider ul{
    list-style: none;
    font-size: 2.5rem;
}
.slider a{
    text-decoration: none;
    color: black;
}
.slider .under .under-links{
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
}

  