#loader{
    z-index: 2000000;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
}
#loader > .wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    position: relative;
    align-items: center;
}
#loader .stripes{
    background-color: var(--color1);
    height: 100%;
    width: 100%;
}
#loader .wrapper h1{
    position: absolute;
    text-align: center;
    width: 100%;
    color: white;
    font-family: "Lora", serif;
    letter-spacing: 2rem;
    font-size: clamp(3rem, 10vw, 7rem);
}