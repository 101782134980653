header {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
img.bg-img {
  width: 100%;
  height: 100%;
  filter: blur(5px) opacity(0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}
header > div {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 90%;
  height: 100%;
}
header h1 {
  color: var(--color1);
  font-size: clamp(36px, 7vw, 64px);
  font-family: "Lora", serif;
}
header .upper {
  display: flex;
  justify-content: space-between;
  top: 200px;
  font-size: clamp(1.4rem, 3vw, 36px);
  position: absolute;
  width: 100%;
}
  

header .lower {
  position: absolute;
  bottom: 20%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-transform: uppercase;
  font-size: clamp(30px, 5vw, 36px);
}
.slogan > div {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 930px) {
  header .lower{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 5%;
    gap: 10px;
  }
  .slogan > div{
    justify-content: center;
  }
}
.slogan .dot {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: var(--color1);
}
