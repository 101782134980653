@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400..700&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  /* border: 1px solid black; */
}
:root {
  --ease: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  --text-dark: #1E1E1E;
  --text-light: #e6e6e6;
  --color1: #00386F;
}
body{
  color: var(--text-dark);
}
main {
  width: 100%;
  background-color: white;
  padding: 8rem 1rem;
}
a{
  color: var(--text-dark);

}
.heading-1{
  text-align: center;
  font-family: 'Lora', serif;
  color: var(--color1);
  font-size: 3rem;

}
.mt-5{
  margin-top: 50px;
}
.mt-2{
  margin-top: 20px;
}
.heading-1.left{
  text-align: left;
}
.members{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  flex-wrap: wrap;
}

.members .card{
  width: 200px;
  height: 260px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  top: 0;
  cursor: pointer;
  margin: 10px 10px;
  /* box-shadow: -6px 4px 7px 0px rgba(0, 0, 0, 0.582); */
}
.members .card img{
  width: 100%;
  height: 100%;
  pointer-events: none; 
  object-fit: cover;
  background-color: #B8B8B8;
}
.members .img-hover{
  background-color: #000000b7;
  backdrop-filter: blur(20px);
  position: fixed;
  width: 0;
  pointer-events: none;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #0080ff;
  font-size: 1.4rem;
  border-radius: 20px;
  z-index: 110;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  transition-property: opacity, width;
  transition-duration: 200ms;
  transition-timing-function: var(--ease);
}
.members .img-hover.active{
  opacity: 1;
  width: 250px;

}
.members .img-hover .department{
  color: white;
  font-size: 0.9rem;
  padding: 1rem;
}
#committees{
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  
}
@media screen and (max-width: 930px) {
  #committees{
    transform: scale(0.8);
  }
}
@media screen and (max-width: 640px) {
  #committees{
    transform: scale(0.6);
  }
}
#committees .bg{
  position: absolute;
  width: 800px;
  z-index: -1;
  height: 800px;
  opacity: 0.1;
  background-color: transparent;
}
#committees > h1{
  position: absolute;
}
#committees .container{
  position: relative;
  width: 600px;
  height: 600px;
  /* border: 3px solid var(--color1); */
  border-radius: 50%;
}
#committees img{
  /* background-color: black; */
  border-radius: 50%;
  width: 150px;
  height: 150px;
  transform: rotate(calc(-360deg/7 * var(--i)));
}

#committees .icon {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -50%;
}

#committees .committee{
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
  opacity: 0;
}
#committees .committee::after{
  content: attr(data-name);
}
#committees .committee img{
  z-index: -1;

}
#committees-page{
  padding: 10rem 1rem;
}

#committees-page h1{
  font-family: 'Lora', serif;
  color: var(--color1);
  font-size: 3rem;
}
.para-1{
  font-size: 1.5rem; 
  padding: clamp(1rem, 5vw, 3rem);
}
.flex {
  display: flex;
  justify-content: space-between;
}
.testimonial-image{
  width: 100%;
  max-width: 450px;
  object-fit: cover;
  max-height: 550px;
  height: 100%;
  border-radius: 18px;
  border: 1px solid var(--text-dark);
}
.testimonial-image.full{
  width: fit-content;
}

.bg-committees-block{
  position: absolute;
  z-index: -1;
  width: 50%;
  opacity: 0.3;
}
.committees-block p{
  margin-top: 1rem;
}